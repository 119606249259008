x<template>
    <main-template>
        <template slot-scope="{logo}">
            <fetch-siren-root :linkName='linkName' :link="link" :logo="logo">

            <div slot-scope="{ response, logo }">
                <loading v-if="!response"></loading>
                <template v-else>
                    <page-header :logo="logo">
                        <div class="md:flex justify-between w-full">
                            <div class="flex items-center text-secondary-lightblue text-xl font-bold">
                                <span class="text-secondary-darkblue">
                                    Traccar Devices
                                </span>
                            </div>
                        </div>

                    </page-header>
                    <traccar-api-paginated-list :response="traccarDetails"></traccar-api-paginated-list>

                </template>
            </div>
        </fetch-siren-root>
        </template>
    </main-template>
</template>

<script>
import axios from "axios";
import Button from "@/v2/buttons/Button";
import FetchSirenRoot from "@/components/FetchSirenRoot";
import FormAction from "@/v2/components/FormAction";
import MainTemplate from "@/v2/templates/MainTemplate";
import Loading from "@/components/Loading";
import PageHeader from "@/v2/components/Header";
import TraccarApiPaginatedList from "@/v2/paginatedLists/TraccarApiPaginatedList.vue";
export default {
    name: "Traccar",
    data() {
        return {
            loading: false,
            pdfLoading: false,
            showDownloadForm: false,
            displayOnSiteIdDetails: false,
            onSiteIdResource: null,
            traccarDetails:null
        }
    },
    components: {
        TraccarApiPaginatedList,
        PageHeader,
        MainTemplate,
        Loading,
        FetchSirenRoot,
        'a-button' : Button,
        FormAction,
    },
    mounted(){
        let that = this;
        axios.get('https://traccar.companiesms.co.uk/api/devices?all=true',{
            headers: {
                'Authorization': 'Basic cm9iZXJ0QGJldHRyLnNvZnR3YXJlOlVWI3ltcWpYdnUyaWkydEc=',
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
            .then(function (response) {
                that.traccarDetails = response.data;
            })
            .catch(function (error) {
                console.log(error);
            });
    },
    computed: {
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },
        remainingActions() {
            return this.response.body.actions
                .filter(action =>
                    action.name !== 'update'
                );
        },

    },
    methods: {
        navigateToClient(response) {
            let selfLink = response.body.links.filter(link => link.rels.contains('self')).first().href;
            let parts = selfLink.split('/api');
            this.$router.push(parts[1]);
        },
        setOnSiteId(response){
            this.onSiteIdResource = response.body.entities.filter(entity => entity.rels.contains('on-site-id')).first().entity;
            return this.onSiteIdResource;
        }
    }
}
</script>

<style scoped>

</style>
